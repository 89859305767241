module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"static/tempsnip-233309f36c6b3328b6101fcbbc87077b.png","icons":[{"src":"static/tempsnip-233309f36c6b3328b6101fcbbc87077b.png","sizes":"192x192","type":"image/png"},{"src":"static/tempsnip-233309f36c6b3328b6101fcbbc87077b.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5dd643203fb9624b95ed349f65a7e6f8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://libertywaterproofing.com/wordpress/graphql","useACF":true,"verbose":true,"develop":{"hardCacheMediaFiles":true},"debug":{"graphql":{"writeQueriesToDisk":true}},"html":{"fallbackImageMaxWidth":800},"excludeFieldNames":["blocksJSON","saveContent"],"type":{"Post":{"limit":null},"CoreParagraphBlockAttributesV2":{"exclude":true}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-basement-js": () => import("./../../../src/pages/basement.js" /* webpackChunkName: "component---src-pages-basement-js" */),
  "component---src-pages-bathroom-js": () => import("./../../../src/pages/bathroom.js" /* webpackChunkName: "component---src-pages-bathroom-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-flat-roof-js": () => import("./../../../src/pages/flat-roof.js" /* webpackChunkName: "component---src-pages-flat-roof-js" */),
  "component---src-pages-garage-js": () => import("./../../../src/pages/garage.js" /* webpackChunkName: "component---src-pages-garage-js" */),
  "component---src-pages-green-roof-js": () => import("./../../../src/pages/green-roof.js" /* webpackChunkName: "component---src-pages-green-roof-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pool-js": () => import("./../../../src/pages/pool.js" /* webpackChunkName: "component---src-pages-pool-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-terrace-js": () => import("./../../../src/pages/terrace.js" /* webpackChunkName: "component---src-pages-terrace-js" */),
  "component---src-templates-work-temp-js": () => import("./../../../src/templates/WorkTemp.js" /* webpackChunkName: "component---src-templates-work-temp-js" */)
}

